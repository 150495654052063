<template>
  <el-dialog
    visible
    :show-close="false"
    custom-class="rounded document-sending-modal"
    width="600px"
    @close="$emit('close')"
  >
    <template #title>
      <div class="d-flex justify-content-between align-items-center p-4 border-bottom">
        <h3>{{ $t('bookkeeping.send') }}</h3>
        <Button type="text" class="p-0 text-typography-primary action-btn" @click="$emit('close')">
          <CloseIcon />
        </Button>
      </div>
    </template>
    <div class="p-4">
      <el-form ref="form" :model="formModel" :show-message="false" @submit.native.prevent="handleSendButtonClick">
        <h3 class="mb-2">{{ $t(`bookkeeping.sendModal.documentsToSend.header.billing`) }}</h3>
        <div class="d-flex align-items-center gap-1 mb-5">
          <template v-if="toDate">{{
            $tc(`bookkeeping.sendModal.documentsToSend.description.billing`, sendCount, {
              fromDate: formatDate(fromDate),
              toDate: formatDate(toDate),
              count: sendCount,
            })
          }}</template>
          <div v-else class="d-flex align-items-center gap-1">
            {{
              $tc(`bookkeeping.sendModal.documentsToSend.descriptionWithInput.billing`, sendCount, {
                fromDate: formatDate(fromDate),
                count: sendCount,
              })
            }}
            <el-form-item prop="toDate" class="mb-0" required style="width: 125px">
              <el-date-picker
                v-model="formModel.toDate"
                size="small"
                type="date"
                :format="$direction === 'ltr' ? 'MM/dd/yyyy' : 'dd.MM.yyyy'"
                class="w-auto"
                :clearable="false"
                :picker-options="pickerOptions"
              />
            </el-form-item>
          </div>
          ({{ $t('bookkeeping.sendModal.documentsToSend.included') }})
        </div>
      </el-form>
    </div>
    <template #footer>
      <div class="pb-4 px-4">
        <Button type="secondary" @click="$emit('close')">
          {{ $t('commons.cancel') }}
        </Button>
        <Button @click="handleSendButtonClick">
          {{ $t('bookkeeping.sendModal.execution') }}
        </Button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { ref, reactive } from 'vue';
import { DateTime } from 'luxon';

import { Button } from '@/modules/core';
import { CloseIcon } from '@/assets/icons';

import { formatDate } from '../formatters';

export default {
  components: { Button, CloseIcon },
  props: {
    docs: { type: Array, required: true },
    fromDate: { type: Number, required: true },
    toDate: { type: Number, default: null },
  },
  setup(props) {
    const previousMonthLastDay = DateTime.local().startOf('month').minus({ day: 1 }).endOf('day').toJSDate();
    const initialToDate = props.fromDate > previousMonthLastDay ? null : previousMonthLastDay;

    const sendCount = ref(0);
    sendCount.value = initialToDate
      ? props.docs.filter(({ date }) => date <= DateTime.fromJSDate(initialToDate).endOf('day').toJSDate()).length
      : props.docs.length;

    return {
      sendCount,
      formatDate,
      isOtherEmailSelected: ref(false),
      formModel: reactive({
        toDate: initialToDate,
        otherEmail: null,
      }),
    };
  },
  computed: {
    pickerOptions() {
      const localFromDate = DateTime.fromMillis(this.fromDate);
      const utcOffset = localFromDate.offset;
      const utcFromDate = localFromDate.minus({ minutes: utcOffset }).toJSDate();
      return { disabledDate: (date) => date < utcFromDate };
    },
  },
  watch: {
    'formModel.toDate'(toDate) {
      this.sendCount = this.docs.filter(
        ({ date }) => date <= DateTime.fromJSDate(toDate).endOf('day').toJSDate()
      ).length;
    },
  },
  methods: {
    handleSendButtonClick() {
      if (!this.toDate || this.resend) {
        this.$refs.form.validate((valid) => {
          if (valid)
            this.$emit('send', {
              toDate: DateTime.fromJSDate(this.formModel.toDate).endOf('day').toJSDate(),
              email: this.formModel.otherEmail,
            });
        });
      } else this.$emit('send');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.action-btn {
  height: fit-content;
  &:hover {
    background: $secondary;
  }
}

:deep(.document-sending-modal) {
  .el-dialog__header,
  .el-dialog__body,
  .el-dialog__footer,
  .el-input__inner {
    padding: 0;
    color: $typography-primary;
  }
}
</style>
