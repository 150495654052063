<template>
  <el-dialog visible :show-close="false" custom-class="rounded bookkeeper-form" width="459px" @close="$emit('close')">
    <template #title>
      <div class="d-flex justify-content-between align-items-center p-4 border-bottom">
        <h2 class="fw-bold">{{ translate('documentTitle') }}</h2>
        <Button type="text" class="p-0 text-typography-primary action-btn" @click="$emit('close')">
          <CloseIcon />
        </Button>
      </div>
    </template>
    <div v-loading="loading" class="p-5">
      <el-form
        ref="form"
        :model="formSendingModel"
        label-position="top"
        :show-message="false"
        @submit.native.prevent="onSubmit"
      >
        <div class="mb-3">
          <h3 class="fw-bold">
            {{ translate('field.autoSend.document.title') }}
          </h3>
          <el-form-item required :error="error" :label="translate('header.document')" prop="email" class="m-0 mt-1">
            <el-input v-model="formSendingModel.email" dir="ltr" />
          </el-form-item>
          <el-form-item class="my-1">
            <el-checkbox v-model="formSendingModel.autoSend">
              {{ translate('field.autoSend.document.label') }}
            </el-checkbox>
            <el-tooltip placement="top" effect="dark">
              <QuestionMarkNoFillIcon class="mx-1" width="10" height="10" />
              <div slot="content">
                {{ translate('field.autoSend.document.tooltip.firstLine') }}<br />{{
                  translate('field.autoSend.document.tooltip.secondLine')
                }}
              </div>
            </el-tooltip>
          </el-form-item>
        </div>
        <div class="mt-6">
          <h3 class="fw-bold">{{ translate('field.documentTypes.title') }}</h3>
          <el-form-item class="my-0 pt-2 documentTypesCheckbox">
            <el-checkbox v-model="formSendingModel.documentTypes.invoice">
              {{ translate('field.documentTypes.invoice') }}
            </el-checkbox>
          </el-form-item>
          <el-form-item class="my-0 documentTypesCheckbox">
            <el-checkbox v-model="formSendingModel.documentTypes.invoiceReceipt">
              {{ translate('field.documentTypes.invoiceReceipt') }}
            </el-checkbox>
          </el-form-item>
          <el-form-item class="my-0 documentTypesCheckbox">
            <el-checkbox v-model="formSendingModel.documentTypes.receipt">
              {{ translate('field.documentTypes.receipt') }}
            </el-checkbox>
          </el-form-item>
          <el-form-item class="my-0 documentTypesCheckbox">
            <el-checkbox v-model="formSendingModel.documentTypes.creditNote">
              {{ translate('field.documentTypes.creditNote') }}
            </el-checkbox>
          </el-form-item>
          <el-form-item class="my-0 documentTypesCheckbox">
            <el-checkbox v-model="formSendingModel.documentTypes.billInvoice">
              {{ translate('field.documentTypes.billInvoice') }}
            </el-checkbox>
          </el-form-item>
          <el-form-item class="my-0 documentTypesCheckbox">
            <el-checkbox v-model="formSendingModel.documentTypes.billInvoiceReceipt">
              {{ translate('field.documentTypes.billInvoiceReceipt') }}
            </el-checkbox>
          </el-form-item>
          <el-form-item class="my-0 documentTypesCheckbox">
            <el-checkbox v-model="formSendingModel.documentTypes.other">
              {{ translate('field.documentTypes.other') }}
            </el-checkbox>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <template #footer>
      <div class="pb-4 px-4">
        <Button type="secondary" @click="$emit('close')">
          {{ $t('commons.cancel') }}
        </Button>
        <Button @click="onSubmit">
          {{ $t('commons.save') }}
        </Button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { computed } from 'vue';
import { isNil, reject, omit } from 'ramda';

import { CloseIcon, QuestionMarkNoFillIcon } from '@/assets/icons';
import { Button } from '@/modules/core';
import { useTenancy } from '@/modules/auth';
import { useBusinessRelationsGet } from '@/modules/permissions';

import { useConfigCreate, useConfigUpdate } from '../composition/config';
import { SENDING_METHOD } from '../constants';

const DEFAULT_DOCUMENT_TYPES = {
  invoice: false,
  invoiceReceipt: false,
  receipt: false,
  creditNote: false,
  billInvoice: false,
  billInvoiceReceipt: false,
  other: false,
};

export default {
  components: { CloseIcon, QuestionMarkNoFillIcon, Button },
  props: {
    config: { type: Object, required: true },
  },
  setup() {
    const { currentTenant } = useTenancy();
    const { createConfig: createSending } = useConfigCreate(computed(() => currentTenant.value.id));
    const { updateConfig: updateSending } = useConfigUpdate();
    const { businessRelations, loading: relationsLoading } = useBusinessRelationsGet(
      computed(() => ({ providerBusinessId: currentTenant.value.id }))
    );

    return {
      currentTenant,
      createSending,
      updateSending,
      businessRelations,
      relationsLoading,
      SENDING_METHOD,
    };
  },
  data() {
    const sendingData = this.config.documentSending;

    const documentTypes = sendingData?.documentTypes
      ? omit(['__typename'], sendingData.documentTypes)
      : { ...DEFAULT_DOCUMENT_TYPES };

    return {
      loading: false,
      error: null,
      formSendingModel: {
        email: sendingData?.email,
        autoSend: sendingData?.autoSend,
        ...(documentTypes && { documentTypes }),
      },
    };
  },
  methods: {
    translate(key) {
      return this.$t(`bookkeeping.bookkeepingManagement.bookkeeperInfo.createForm.${key}`);
    },
    onSubmit() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.loading = true;
          this.error = null;

          const data = reject(isNil, {
            documentSending: {
              ...this.formSendingModel,
              email: this.formSendingModel.email,
            },
          });

          try {
            if (!this.config.id) await this.createSending({ data: { ...data, businessId: this.currentTenant.id } });
            else await this.updateSending({ id: this.config.id, data });
            this.$emit('close');
          } catch (error) {
            this.loading = false;
            this.error = 'bad email syntax';
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.action-btn {
  height: fit-content;
  &:hover {
    background: $secondary;
  }
}

:deep(.bookkeeper-form) {
  .el-dialog__header,
  .el-dialog__body,
  .el-dialog__footer {
    padding: 0;
    color: $typography-primary;
  }
}

:deep(.el-input__inner) {
  background: $gray;
  height: 2rem;
  border-color: $gray;
}

:deep(.el-checkbox__label, .el-checkbox__input.is-checked + .el-checkbox__label) {
  font-weight: $font-weight-normal;
  color: $typography-primary !important;
}

:deep(.documentTypesCheckbox .el-form-item__content) {
  line-height: 2rem;
}

.no-bookkeepers {
  background: #f4f4f5;
  color: #909399;
  padding: 1rem;
  height: 3.25rem;
  display: flex;
  align-items: center;
  border-radius: 4px;

  .no-bookkeepers-text {
    width: 365px;
  }
}

.information-icon {
  margin-left: 8px;
}

.word-break {
  word-break: normal;
}
</style>
