<template>
  <el-dialog visible :show-close="false" custom-class="rounded bookkeeper-form" width="459px" @close="$emit('close')">
    <template #title>
      <div class="d-flex justify-content-between align-items-center p-4 border-bottom">
        <h2 class="fw-bold">{{ translate('billingTitle') }}</h2>
        <Button type="text" class="p-0 text-typography-primary action-btn" @click="$emit('close')">
          <CloseIcon />
        </Button>
      </div>
    </template>
    <div v-loading="loading" class="p-5">
      <el-form
        ref="form"
        :model="formSendingModel"
        label-position="top"
        :show-message="false"
        @submit.native.prevent="onSubmit"
      >
        <div class="mb-3">
          <h3 class="fw-bold">
            {{ translate('field.autoSend.billing.title') }}
          </h3>
          <div class="d-flex mt-4 mb-3">
            <el-form-item required prop="sendingMethod" class="d-flex mb-0">
              <el-radio-group v-model="formSendingModel.sendingMethod">
                <div class="d-flex flex-column">
                  <el-radio class="mb-2" label="email">
                    {{ translate('field.email') }}
                  </el-radio>
                  <el-radio label="platform">
                    {{ translate('field.clarityPlatform') }}
                  </el-radio>
                </div>
              </el-radio-group>
            </el-form-item>
          </div>
          <el-form-item class="m-0">
            <div class="d-flex align-items-center">
              <el-checkbox
                v-model="formSendingModel.csvExport"
                :disabled="
                  currentTenant.countryCode === 'IL' || formSendingModel.sendingMethod === SENDING_METHOD.PLATFORM
                "
              >
                {{ translate('field.csvExport.label') }}
              </el-checkbox>
              <el-tooltip v-if="currentTenant.countryCode === 'IL'" placement="top" effect="dark">
                <QuestionMarkNoFillIcon class="mx-1" width="10" height="10" fill="#9295a5" />
                <div slot="content">
                  {{ translate('field.csvExport.tooltip') }}
                </div>
              </el-tooltip>
            </div>
          </el-form-item>
          <el-form-item
            v-if="formSendingModel.sendingMethod === SENDING_METHOD.PLATFORM"
            :error="error"
            prop="bookkeeperId"
            class="m-0 mt-2 mb-5"
          >
            <div v-if="relatedBookkeeper" class="d-flex align-items-center">
              <div class="shop-icon-wrapper bg-secondary rounded-pill mx-1">
                <ShopIcon class="shop-icon text-typography-secondary" />
              </div>
              {{ relatedBookkeeper?.receiverBusinessName }}
            </div>
            <div v-else class="d-flex align-items-center">
              <div class="no-bookkeepers">
                <InformationFullIcon class="information-icon word-break" height="16" width="16" />
                <p class="ms-1 no-bookkeepers-text">{{ translate('noBookkeepers') }}</p>
              </div>
            </div>
          </el-form-item>
          <el-form-item
            v-if="formSendingModel.sendingMethod === SENDING_METHOD.EMAIL"
            required
            :error="error"
            :label="translate('header.billing')"
            prop="email"
          >
            <el-input v-model="formSendingModel.email" dir="ltr" />
          </el-form-item>
          <el-form-item class="my-1">
            <el-checkbox v-model="formSendingModel.autoSend">
              {{ translate('field.autoSend.billing.label') }}
            </el-checkbox>
            <el-tooltip placement="top" effect="dark">
              <QuestionMarkNoFillIcon class="mx-1" width="10" height="10" />
              <div slot="content">
                {{ translate('field.autoSend.billing.tooltip.firstLine') }}<br />{{
                  translate('field.autoSend.billing.tooltip.secondLine')
                }}
              </div>
            </el-tooltip>
          </el-form-item>
        </div>
        <div class="mt-6">
          <h3 class="fw-bold">{{ translate('field.autoSend.billing.dataExportStartDate') }}</h3>
          <div class="d-flex align-items-center my-2 gap-2">
            <el-form-item prop="fromDate" class="mb-0" required style="width: 115px">
              <el-date-picker
                v-model="formSendingModel.fromDate"
                size="small"
                type="date"
                format="d.M.yyyy"
                class="w-auto"
                :clearable="false"
              />
            </el-form-item>
          </div>
          <p class="text-typography-secondary word-break">
            {{ translate('field.autoSend.billing.dataExportStartDateExplanation') }}
          </p>
        </div>
      </el-form>
    </div>
    <template #footer>
      <div class="pb-4 px-4">
        <Button type="secondary" @click="$emit('close')">
          {{ $t('commons.cancel') }}
        </Button>
        <Button :disabled="saveDisabled" @click="onSubmit">
          {{ $t('commons.save') }}
        </Button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { computed } from 'vue';
import { isNil, reject } from 'ramda';
import { DateTime } from 'luxon';

import { CloseIcon, QuestionMarkNoFillIcon, ShopIcon, InformationFullIcon } from '@/assets/icons';
import { Button } from '@/modules/core';
import { useTenancy } from '@/modules/auth';
import { useBusinessRelationsGet } from '@/modules/permissions';

import { useConfigCreate, useConfigUpdate } from '../composition/config';
import { SENDING_METHOD } from '../constants';

export default {
  components: { CloseIcon, QuestionMarkNoFillIcon, Button, ShopIcon, InformationFullIcon },
  props: {
    config: { type: Object, required: true },
  },
  setup() {
    const { currentTenant } = useTenancy();
    const { createConfig: createSending } = useConfigCreate(computed(() => currentTenant.value.id));
    const { updateConfig: updateSending } = useConfigUpdate();
    const { businessRelations, loading: relationsLoading } = useBusinessRelationsGet(
      computed(() => ({ providerBusinessId: currentTenant.value.id }))
    );

    return {
      currentTenant,
      createSending,
      updateSending,
      businessRelations,
      relationsLoading,
      SENDING_METHOD,
    };
  },
  data() {
    const sendingData = this.config.billingSending;

    return {
      loading: false,
      error: null,
      formSendingModel: {
        email: sendingData?.email,
        sendingMethod: sendingData?.sendingMethod ?? 'email',
        autoSend: sendingData?.autoSend,
        csvExport: sendingData?.csvExport,
        ...(sendingData?.fromDate && { fromDate: sendingData?.fromDate }),
      },
    };
  },
  computed: {
    saveDisabled() {
      return this.formSendingModel.sendingMethod === SENDING_METHOD.PLATFORM && !this.relatedBookkeeper;
    },
    relatedBookkeeper() {
      return this.businessRelations?.nodes.find(({ type }) => type === 'bookkeeping');
    },
  },
  methods: {
    translate(key) {
      return this.$t(`bookkeeping.bookkeepingManagement.bookkeeperInfo.createForm.${key}`);
    },
    onSubmit() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.loading = true;
          this.error = null;

          const data = reject(isNil, {
            billingSending: this.formSendingModel.fromDate
              ? {
                  ...this.formSendingModel,
                  bookkeeperId:
                    this.formSendingModel.sendingMethod === SENDING_METHOD.PLATFORM
                      ? this.relatedBookkeeper.receiverBusinessId
                      : undefined,
                  fromDate: DateTime.fromJSDate(new Date(this.formSendingModel.fromDate)).toISODate(),
                  email:
                    this.formSendingModel.sendingMethod === SENDING_METHOD.PLATFORM
                      ? undefined
                      : this.formSendingModel.email,
                  csvExport:
                    this.formSendingModel.sendingMethod === SENDING_METHOD.PLATFORM ||
                    this.currentTenant.countryCode === 'IL'
                      ? false
                      : this.formSendingModel.csvExport,
                }
              : {
                  ...this.formSendingModel,
                  bookkeeperId:
                    this.formSendingModel.sendingMethod === SENDING_METHOD.PLATFORM
                      ? this.relatedBookkeeper.receiverBusinessId
                      : undefined,
                  email:
                    this.formSendingModel.sendingMethod === SENDING_METHOD.PLATFORM
                      ? undefined
                      : this.formSendingModel.email,
                  csvExport:
                    this.formSendingModel.sendingMethod === SENDING_METHOD.PLATFORM ||
                    this.currentTenant.countryCode === 'IL'
                      ? false
                      : this.formSendingModel.csvExport,
                },
          });

          try {
            if (!this.config.id) await this.createSending({ data: { ...data, businessId: this.currentTenant.id } });
            else await this.updateSending({ id: this.config.id, data });
            this.$emit('close');
          } catch (error) {
            this.loading = false;
            this.error = 'bad email syntax';
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.action-btn {
  height: fit-content;
  &:hover {
    background: $secondary;
  }
}

:deep(.bookkeeper-form) {
  .el-dialog__header,
  .el-dialog__body,
  .el-dialog__footer {
    padding: 0;
    color: $typography-primary;
  }
}

:deep(.el-input__inner) {
  background: $gray;
  height: 2rem;
  border-color: $gray;
}

:deep(.el-checkbox__label, .el-checkbox__input.is-checked + .el-checkbox__label) {
  font-weight: $font-weight-normal;
  color: $typography-primary !important;
}
:deep(.el-checkbox__input.is-disabled + .el-checkbox__label) {
  font-weight: $font-weight-normal;
  color: $typography-secondary !important;
}

.shop-icon-wrapper {
  height: 32px;
  width: 32px;
  margin: 0 !important;
  margin-inline-end: 0.5rem !important;
  .shop-icon {
    display: block;
    margin: auto;
    margin-top: 4px;
  }
}

.no-bookkeepers {
  background: #f4f4f5;
  color: #909399;
  padding: 1rem;
  height: 3.25rem;
  display: flex;
  align-items: center;
  border-radius: 4px;

  .no-bookkeepers-text {
    width: 365px;
  }
}

.information-icon {
  margin-left: 8px;
}

.word-break {
  word-break: normal;
}
</style>
